/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "examples/Cards/BookingCard";

import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as service from '../../../services/service';

function Analytics() {
  const [regionCountData, setRegionCountData] = useState([]);
  const [typeCountData, setTypeCountData] = useState([]);
  const [typeRegionCountData, setTypeRegionCountData] = useState([]);
  const [regionCountDataWater, setRegionCountDataWater] = useState([]);
  const [typeCountDataWater, setTypeCountDataWater] = useState([]);
  const [typeRegionCountDataWater, setTypeRegionCountDataWater] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY

  const getRegionWiseCounts = () => {
    fetch(
      service.GET_REGION_WISE_DASHBOARD,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setRegionCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };

  const getRegionWiseCountsForWater = () => {
    fetch(
      service.GET_REGION_WISE_DASHBOARD_FOR_WATER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setRegionCountDataWater(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };

  const getTourTypeWiseCounts = () => {
    fetch(
      service.GET_TOUR_TYPE_WISE_DASHBOARD,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setTypeCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };

  const getTourTypeWiseCountsForWater = () => {
    fetch(
      service.GET_TOUR_TYPE_WISE_DASHBOARD_FOR_WATER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setTypeCountDataWater(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };

  const getTourTypeWiseRegionWiseCounts = () => {
    fetch(
      service.GET_TOUR_TYPE_WISE_REGION_WISE_DASHBOARD,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setTypeRegionCountData(result);

      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };

  const getTourTypeWiseRegionWiseCountsForWater = () => {
    fetch(
      service.GET_TOUR_TYPE_WISE_REGION_WISE_DASHBOARD_FOR_WATER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'XApiKey': apiKey
        },
        body: JSON.stringify({
          UserRole: JSON.parse(sessionStorage.getItem('userData')).userRole,
          RegionId: JSON.parse(sessionStorage.getItem('userData')).regionId,
        })
      })
      .then(res => res.json())
      .then(result => {
        setTypeRegionCountDataWater(result);

      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
  };
  useEffect(() => {
    getRegionWiseCounts();
    getTourTypeWiseCounts();
    getTourTypeWiseRegionWiseCounts();
    getRegionWiseCountsForWater();
    getTourTypeWiseCountsForWater();
    getTourTypeWiseRegionWiseCountsForWater();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid>
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Region Wise Analysis For Agro, Adventure(Land, Air, Water (other than Konkan))</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvRegionWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Region
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {regionCountData.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.region}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvRegionWise" className="info"
                  filename="RegionWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
        <br />
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Region Wise Analysis For Adventure(Water(Konkan)), Industrial Status And Caravan</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvRegionWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Region
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Assistant Diretor Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Joint Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {regionCountDataWater.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.region}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.assistantDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.jointDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.directorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvRegionWise" className="info"
                  filename="RegionWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
        <br />
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Tour Type Wise Analysis For Agro, Adventure(Land, Air, Water (other than Konkan))</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvTypeWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Tour Type
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {typeCountData.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.tourType}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvTypeWise" className="info"
                  filename="TourTypeWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
        <br />
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Tour Type Wise Analysis For Adventure(Water(Konkan)), Industrial Status And Caravan</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvTypeWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Tour Type
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Assistant Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Joint Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {typeCountDataWater.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.tourType}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.assistantDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.jointDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.directorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvTypeWise" className="info"
                  filename="TourTypeWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
        <br />
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Tour Type Wise Region Wise Analysis For Agro, Adventure(Land, Air, Water (other than Konkan))</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvTypeWiseRegionWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Tour Type
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Region
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {typeRegionCountData.map((row) => (
                  <TableRow key={row.pk}>
                    <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.tourType}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.region}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvTypeWiseRegionWise" className="info"
                  filename="TourTypeWiseRegionWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
        <br />
        <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Tour Type Wise Region Wise Analysis For Adventure(Water(Konkan)), Industrial Status And Caravan</div>
        <MDBox mb={1.5}>
          <TableContainer component={Paper}>
            <Table id="dvTypeWiseRegionWise" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <TableRow>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Tour Type
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Region
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Document Verified
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Assistant Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Joint Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Director Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Approved
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Site Visit Rejected
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Issued
                </TableCell>
                <TableCell width="10%" align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Certificate Pending
                </TableCell>
                <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  Total Collection
                </TableCell>
              </TableRow>
              <TableBody>
                {typeRegionCountDataWater.map((row) => (
                  <TableRow key={row.pk}>
                    <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.tourType}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.region}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.total}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.approved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.rejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.assistantDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.jointDirectorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.directorApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteApproved}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.siteRejected}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateIssued}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.certificateDue}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {row.revenue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableRow>
              <TableCell align="center">
                <ReactHTMLTableToExcel table="dvTypeWiseRegionWise" className="info"
                  filename="TourTypeWiseRegionWiseAnalysis" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                />
              </TableCell>
            </TableRow>
          </Table>
        </MDBox>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
