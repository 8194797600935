
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { Document, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import * as moment from 'moment';
import certLogo from "assets/images/CertificateLogo.png";
import satyaLogo from "assets/images/CertificateLogoOld.png";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function GenerateAccomodationCertificate() {
    const [data, setData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const certificateId = JSON.parse(sessionStorage.getItem('CertificateId'));
    const certificateNumber = JSON.parse(sessionStorage.getItem('CertificateNumber'));
    const outwardNumber = JSON.parse(sessionStorage.getItem('OutwardNumber'));
    const validFrom = JSON.parse(sessionStorage.getItem('ValidFrom'));
    const validTill = JSON.parse(sessionStorage.getItem('ValidTill'));
    const issueDate = JSON.parse(sessionStorage.getItem('IssueDate'));
    const navigate = useNavigate();
    const params = useParams();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ACCOMODATION_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getRegistrationById();
        }
    }, []);


    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        const divToPrint = document.querySelector('#divForm');
        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            // eslint-disable-next-line new-cap
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            // const doc1 = addWaterMark(doc);
            const blob = doc.output('blob');
            // doc.toBlob(blob => {
            const certFile = new File([blob], 'Certificate.pdf');
            const formData = new FormData();
            formData.append("AccomodationId", params.id ? params.id : null);
            formData.append("AccomodationCertificateId", certificateId);
            formData.append("IssueDate", (new Date(issueDate)).toUTCString());
            formData.append("ValidFrom", (new Date(validFrom)).toUTCString());
            formData.append("ValidTill", (new Date(validTill)).toUTCString());
            formData.append("CertificateNumber", certificateNumber);
            formData.append("OutwardNumber", outwardNumber);
            formData.append("CertificateFile", certFile);
            formData.append("AddedBy", JSON.parse(window.sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_ACCOMODATION_CERTIFICATE,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                  
                        'XApiKey': apiKey
                    },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/accomodationregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/accomodationregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        });
        // }, 'image/png')
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/accomodationregistrations');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Certificate
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                {/* <Document style={{ width: '100%' }}> */}
                                <div id="divForm" width="100%">
                                    <br />
                                    <br />
                                    <Table id="tblCertificate" style={{ "borderWidth": "5px", 'borderColor': "lightcoral", 'borderStyle': 'solid' }}>
                                        <TableRow>
                                            <TableCell align="left">
                                                <br />
                                                <img src={satyaLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                            <TableCell align="right">
                                                <br />
                                                <img src={certLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '22px', fontWeight: 'bold', color: 'maroon' }}>
                                                पर्यटन संचालनालय
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                औद्योगिक दर्जा नोंदणी प्रमाणपत्र
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '16px', }}>
                                                <p style={{ fontWeight: 'bold' }}>संदर्भ</p>
                                                <Table align="center">
                                                    <TableRow>
                                                        <TableCell width="30%" align="right" style={{ fontSize: '15px', }}>1.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन निर्णय क्र.टीडीएस 2020/9/प्र.क्र.502/पर्यटन,दि.03 डिसेंबर 2020</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>2.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन निर्णय क्र.टीडीएस 2020/9/प्र.क्र.502/पर्यटन,दि.04 जानेवारी 2021</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>3.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन निर्णय क्र.टीडीएस 2020/9/प्र.क्र.502/पर्यटन,दि.12 एप्रिल 2021</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>4.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन निर्णय क्र.एमयुएम-2021/प्र.क्र.48/नवि-17, दि.21/06/2021</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>5.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन परिपत्रक क्र.व्हिपीएम-2021/प्र.क्र.43/पं.रा.-4, दि.29 जुन 2021</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>6.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन परिपत्रक क्र.ग्रापाधो-2021/प्र.क्र.52/पापु-07, दि.23 जुन 2021</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ fontSize: '15px', }}>7.</TableCell>
                                                        <TableCell align="left" style={{ fontSize: '15px', }}>शासन पत्र क्र.जमीन-2021/प्र.क्र.03/ज-1अ,महसुल व वन विभाग,दि.08/01/2021</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '18px', fontWeight: 'bold', color: 'maroon' }}>
                                                संदर्भ क्र.1 च्या शासन निर्णयानुसार आपले हॉटेल प्रकल्पास खालीप्रमाणे मान्यता देण्यात येत आहे.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी क्रमांक (Registration No.)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {certificateNumber}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                विभागीय कार्यालय (Divisional Office)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.region}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                आदरातिथ्य हॉटेल / रिसॉर्ट च्या कंपनीचे नाव
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.companyName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                आदरातिथ्य हॉटेल / रिसॉर्टचे नाव
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.hotelName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                आदरातिथ्य हॉटेल प्रकल्पाचे ठिकाण
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.address}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                आदरातिथ्य प्रकल्पातील एकुण कक्ष  (No.of Rooms, Facilities etc)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.roomNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                वीज पुरवठादाराचे नाव व  ग्राहक क्रमांक
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.electricityCompanyDetails}, {data.msebConsumerNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                देय सवलती <br />
                                                1.	वीज दर  – <br />
                                                2.	विद्युत शुल्क – <br />
                                                3.	मालमत्ता कर – <br />
                                                4.	पाणी पट्टी – <br />
                                                5.	विकास कर – <br />
                                                6.	वाढीव F.S.I – <br />
                                                7.	अकृषिक कर आकारणी -
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                औद्योगिक दराने आकारणी
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                तारांकित दर्जा प्रमाणपत्राचा तपशील
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.starCategory}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                किमान मुलभुत दर्जा प्राप्त करण्यासाठी विहीत निकष पुर्ण केले आहेत
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : होय
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                अभिप्राय
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : आपले आदरातिथ्य हॉटेल प्रकल्पास औद्योगिक दर्जा देण्यात येत आहे.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी प्रमाणपत्राचा कालावधी (Registration valid for the period of)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {moment(validFrom).format('DD-MM-YYYY')} To {moment(validTill).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                Issue Date: {moment(issueDate).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell id="tcSignature" colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={signatureImage} alt="sign" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                अटी व शर्ती : <br />
                                                1.	सदर नोंदणी प्रमाणपत्राचा कालावधी सुरुवातीस 5 वर्षांचा असेल. <br />
                                                2.	नोंदणी प्रमाणपत्राचा कालावधी संपन्याअगोदर 15 दिवसांच्या आत नुतणीकरणाकरीता ऑनलाईन अर्ज विहीत केलेल्या शुल्कासह सादर करावा लागेल. <br />
                                                3.	आपले हॉटेल प्रकल्पाची पाहणी करण्याचे अधिकार संचालक, पर्यटन संचालनालय तसेच त्यांनी प्राधिकृत केलेल्या अधिकाऱ्यास असतील. <br />
                                                4.	औद्योगिक दर्जा देणेसंदर्भात विहीत केलेल्या निकष अथवा अटी/शर्ती/नियम यांचा भंग झाल्यास अथवा निकषांची पुतर्ता करीत किंवा होत नसल्यास आपल्या प्रकल्पास देण्यात आलेली मान्यता आपोआप रद्द होईल. <br />
                                                5.	सदर मान्यता रद्द झाल्यास औद्योगिक दर्जा धोरणांतर्गत असणाऱ्या कोणत्याही सवलती अनुज्ञेय असणार नाहीत. <br />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <br />
                                                <br />
                                                {JSON.parse(sessionStorage.getItem('userData')).userName}
                                                <br />
                                                <br />
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={stampImage} alt="stamp" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '16px' }}>
                                                प्रत. <br />
                                                1.     जिल्हाधिकारी, जिल्हाधिकारी कार्यालय - {data.district}, यांनी संदर्भाधिन शासन निर्णयांनुसार अनुज्ञेय असलेल्या सवलती देण्याबाबत उचित कार्यवाही करण्यात यावी, तसेच याबाबतचा अहवाल या कार्यालयास पाठविण्यात यावा. <br />
                                                2.  मुख्य कार्यकारी अधिकारी/आयुक्त  {data.propertyTaxDeptDetails} - {data.district}, संदर्भाधिन शासन निर्णयांनुसार अनुज्ञेय असलेल्या सवलती देण्याबाबत उचित कार्यवाही करण्यात यावी, तसेच याबाबतचा अहवाल या कार्यालयास पाठविण्यात यावा. <br />
                                                3.  मुख्य कार्यकारी अधिकारी/आयुक्त  {data.waterBillDeptDetails} - {data.district}, संदर्भाधिन शासन निर्णयांनुसार अनुज्ञेय असलेल्या सवलती देण्याबाबत उचित कार्यवाही करण्यात यावी, तसेच याबाबतचा अहवाल या कार्यालयास पाठविण्यात यावा. <br />
                                                4.  अधीक्षक अभियंता, {data.electricityCompanyDetails} - {data.district}, संदर्भाधिन शासन निर्णयांनुसार अनुज्ञेय असलेल्या सवलती देण्याबाबत उचित कार्यवाही करण्यात यावी, तसेच याबाबतचा अहवाल या कार्यालयास पाठविण्यात यावा.
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </div>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    Save
                                                </MDButton>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <MDButton color="secondary" onClick={backHandler}>
                                                Back
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".error"]}</div>
                                {/* </Document> */}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default GenerateAccomodationCertificate;