
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
// import { Alert } from "bootstrap";
import * as service from '../../../../services/service';

function AddEditSiteVisitCriteria() {
    const [state, setState] = useState({ criteria: '' });
    const [tourType, setTourType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const tourTypeData = [{ label: "Agro Tour", value: "Agro Tour" }, { label: "Adventure Tour", value: "Adventure Tour" },
    { label: "Caravan", value: "Caravan" }, { label: "Industrial Status Accomodation", value: "Industrial Status Accomodation" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const params = useParams();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_SITE_VISIT_CRITERIA_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        CriteriaId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, criteria: result.criteria });
                        const sta = ({ value: result.tourType, label: result.tourType });
                        setTourType(sta);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!tourType) {
            isValid = false;
            errs[".tourType"] = "Please select type";
        }
        if (!state.criteria) {
            isValid = false;
            errs[".criteria"] = "Please enter criteria";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const tourTypeChangeHandler = (ev) => {
        setTourType(ev);
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_SITE_VISIT_CRITERIA,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        CriteriaId: params.id ? params.id : null,
                        Criteria: state.criteria,
                        TourType: tourType.value,
                        AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/sitevisitcriteria');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/sitevisitcriteria');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                options={tourTypeData}
                                                name="tourType"
                                                value={tourType}
                                                onChange={tourTypeChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                                placeholder="Select Type"
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourType"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Criteria" fullWidth value={state.criteria || ''}
                                                name="criteria"
                                                onChange={changeHandler}
                                                id="criteria"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".criteria"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditSiteVisitCriteria;