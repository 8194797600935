import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
// import SearchIcon from '@mui/icons-material/Search';
// import Stack from '@mui/material/Stack';
import * as service from '../../../services/service';

function SiteVisitCriteria() {    
    const [tourType, setTourType] = useState('');    
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const tourTypeData = [{ label: "Agro Tour", value: "Agro Tour" }, { label: "Adventure Tour", value: "Adventure Tour" },
    { label: "Caravan", value: "Caravan" }, { label: "Industrial Status Accomodation", value: "Industrial Status Accomodation" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const apiKey = process.env.REACT_APP_API_KEY;
    const getDetails = (type) => {
        setLoading(true);
        fetch(
            service.GET_SITE_VISIT_CRITERIA_BY_TYPE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    TourType: type,
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
            setLoading(false);
    };

    // const handleEdit = (id) => {
    //     navigate(`/addeditsitevisitcriteria/${id}`);
    // }

    useEffect(() => {
       
    }, []);

    const tourTypeChangeHandler = (ev) => {
        setTourType(ev);
        getDetails(ev.value)
    }

    const addNew = () => {
        navigate('/addeditsitevisitcriteria/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            <div>
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Site Visit Criteria
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <MDBox mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}>
                                    <Table>
                                        <TableRow>                                            
                                            <TableCell>
                                                <MDBox style={{ fontSize: '14px' }}>
                                                    <Select
                                                        options={tourTypeData}
                                                        name="tourType"
                                                        value={tourType}
                                                        onChange={tourTypeChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                        placeholder="Select Type"
                                                    />
                                                    {/* <div style={{ fontSize: '12px', color: 'red' }}>{error[".state"]}</div> */}
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </MDBox>
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '70%', alignSelf: 'center', }}>
                                            <Table aria-label="simple table">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Criteria</TableCell>                                                    
                                                    {/* <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell> */}
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow
                                                            key={row.criteriaId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >                                                            
                                                            <TableCell>{row.tourType}</TableCell>
                                                            <TableCell>{row.criteria}</TableCell>
                                                            {/* <TableCell><MDButton onClick={() => handleEdit(row.criteriaId)}>Edit</MDButton></TableCell> */}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </div>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout >
    );
}
export default SiteVisitCriteria;