
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as service from '../../services/service';

function Reports() {
    const [agroReportData, setAgroReportData] = useState([]);
    const [adventureReportData, setAdventureReportData] = useState([]);
    const [caravanReportData, setCaravanReportData] = useState([]);
    const [accomodationReportData, setAccomodationReportData] = useState([]);
    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [showAgroReport, setShowAgroReport] = useState(false);
    const [showAdventureReport, setShowAdventureReport] = useState(false);
    const [showAccomodationReport, setShowAccomodationReport] = useState(false);
    const [showCaravanReport, setShowCaravanReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [dtoUser, setDTOUser] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [tourType, setTourType] = useState('');
    const tourTypeData = [{ label: "Agro", value: "Agro" }, { label: "Adventure", value: "Adventure" },
    { label: "Industrial Status Accomodation", value: "Accomodation" }, { label: "Caravan", value: "Caravan" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const apiKey = process.env.REACT_APP_API_KEY;
    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);
            });
    };

    const getRegions = () => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: 1, // for Maharashtra
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setRegionData(data2);
                setRegionId(all);

                if (JSON.parse(window.sessionStorage.getItem('userData')).userRole === 'DTO') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).regionId);
                    setRegionId(st);

                    getDistricts(JSON.parse(sessionStorage.getItem('userData')).regionId);
                }
            });
    };


    useEffect(() => {
        getRegions();
        if (JSON.parse(window.sessionStorage.getItem('userData')).userRole === 'DTO') {
            setDTOUser(true);
        }
        const type = { value: 'Agro', label: 'Agro' };
        setTourType(type);
    }, []);

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictData(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const tourTypeChangeHandler = (ev) => {
        setTourType(ev);
    }

    const handleChange = (event) => {
        setShowAgroReport(false);
        setShowAdventureReport(false);
        setShowAccomodationReport(false);
        setShowCaravanReport(false);
        setReportType(event.target.value);
    };

    function GetAgroRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_AGRO_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(true);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAgroReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetAdventureRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_ADVENTURE_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(true);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAdventureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetCaravanRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_CARAVAN_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(true);
                    setCaravanReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetAccomodationRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_ACCOMODATION_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(true);
                    setShowCaravanReport(false);
                    setAccomodationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedAgroRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_AGRO_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(true);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAgroReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedAdventureRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_ADVENTURE_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(true);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAdventureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedCaravanRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_CARAVAN_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(true);
                    setCaravanReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedAccomodationRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_ACCOMODATION_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(true);
                    setShowCaravanReport(false);
                    setAccomodationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedSiteVisitAgroReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_SITE_VISIT_AGRO_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(true);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAgroReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedSiteVisitAdventureReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_SITE_VISIT_ADVENTURE_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(true);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAdventureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedSiteVisitCaravanReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_SITE_VISIT_CARAVAN_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(true);
                    setCaravanReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetApprovedSiteVisitAccomodationReport() {
        const errs = {};
        fetch(
            service.GET_APPROVED_SITE_VISIT_ACCOMODATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(true);
                    setShowCaravanReport(false);
                    setAccomodationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }


    function GetSiteVisitReminderAgroReport() {
        const errs = {};
        fetch(
            service.GET_SITE_VISIT_REMINDER_AGRO_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(true);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAgroReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetSiteVisitReminderAdventureReport() {
        const errs = {};
        fetch(
            service.GET_SITE_VISIT_REMINDER_ADVENTURE_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(true);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAdventureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetSiteVisitReminderCaravanReport() {
        const errs = {};
        fetch(
            service.GET_SITE_VISIT_REMINDER_CARAVAN_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(true);
                    setCaravanReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetSiteVisitReminderAccomodationReport() {
        const errs = {};
        fetch(
            service.GET_SITE_VISIT_REMINDER_ACCOMODATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(true);
                    setShowCaravanReport(false);
                    setAccomodationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetRenewedAgroRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_RENEWED_AGRO_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(true);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAgroReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetRenewedAdventureRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_RENEWED_ADVENTURE_TOUR_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(true);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                    setAdventureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetRenewedCaravanRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_RENEWED_CARAVAN_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(true);
                    setCaravanReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }

    function GetRenewedAccomodationRegistrationReport() {
        const errs = {};
        fetch(
            service.GET_RENEWED_ACCOMODATION_REGISTRATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: regionId.value ? regionId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(true);
                    setShowCaravanReport(false);
                    setAccomodationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowAgroReport(false);
                    setShowAdventureReport(false);
                    setShowAccomodationReport(false);
                    setShowCaravanReport(false);
                }
            });
    }
    const submitHandler = e => {
        setShowAgroReport(false);
        setShowAdventureReport(false);
        setShowAccomodationReport(false);
        setShowCaravanReport(false);
        e.preventDefault();
        setLoading(true);
        if (!reportType) {
            // eslint-disable-next-line
            alert("Please select report type");
        }
        if (reportType === "applicationdetails") {
            if (tourType.value === "Agro") {
                GetAgroRegistrationReport();
            }
            else if (tourType.value === "Adventure") {
                GetAdventureRegistrationReport();
            }
            else if (tourType.value === "Caravan") {
                GetCaravanRegistrationReport();
            }
            else if (tourType.value === "Accomodation") {
                GetAccomodationRegistrationReport();
            }
        }
        else if (reportType === "approvedapplicationdetails") {
            if (tourType.value === "Agro") {
                GetApprovedAgroRegistrationReport();
            }
            else if (tourType.value === "Adventure") {
                GetApprovedAdventureRegistrationReport();
            }
            else if (tourType.value === "Caravan") {
                GetApprovedCaravanRegistrationReport();
            }
            else if (tourType.value === "Accomodation") {
                GetApprovedAccomodationRegistrationReport();
            }
        }
        else if (reportType === "siteapprovedapplicationdetails") {
            if (tourType.value === "Agro") {
                GetApprovedSiteVisitAgroReport();
            }
            else if (tourType.value === "Adventure") {
                GetApprovedSiteVisitAdventureReport();
            }
            else if (tourType.value === "Caravan") {
                GetApprovedSiteVisitCaravanReport();
            }
            else if (tourType.value === "Accomodation") {
                GetApprovedSiteVisitAccomodationReport();
            }
        }
        else if (reportType === "sitevisitreminderapplicationdetails") {
            if (tourType.value === "Agro") {
                GetSiteVisitReminderAgroReport();
            }
            else if (tourType.value === "Adventure") {
                GetSiteVisitReminderAdventureReport();
            }
            else if (tourType.value === "Caravan") {
                GetSiteVisitReminderCaravanReport();
            }
            else if (tourType.value === "Accomodation") {
                GetSiteVisitReminderAccomodationReport();
            }
        }
        else if (reportType === "renewedapplicationdetails") {
            if (tourType.value === "Agro") {
                GetRenewedAgroRegistrationReport();
            }
            else if (tourType.value === "Adventure") {
                GetRenewedAdventureRegistrationReport();
            }
            else if (tourType.value === "Caravan") {
                GetRenewedCaravanRegistrationReport();
            }
            else if (tourType.value === "Accomodation") {
                GetRenewedAccomodationRegistrationReport();
            }
        }
        setLoading(false);
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    const AgroReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvAgroReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Full Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Address</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Region</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Status</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Verification Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Expected Completion Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Remaining Days</TableCell>
                            : null
                        }
                    </TableRow>
                    {agroReportData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.fullName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantAddress}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.region}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.siteStatus}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.siteVerificationDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.expectedCompletionDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.remainingDays}</TableCell>
                                : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const AdventureReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvAdventureReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Address</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Tourism Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Adventure Tourism Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Adventure Activities</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Registration Date</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Region</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Status</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Verification Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Expected Completion Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Remaining Days</TableCell>
                            : null
                        }
                    </TableRow>
                    {adventureReportData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantAddress}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>Adventure</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.activityCategory}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.activities}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.addedDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.region}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.siteStatus}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.siteVerificationDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.expectedCompletionDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.remainingDays}</TableCell>
                                : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const AccomodationReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvAccomodationReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Hotel/Resort Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Address</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Region</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Status</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Verification Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Expected Completion Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Remaining Days</TableCell>
                            : null
                        }
                    </TableRow>
                    {accomodationReportData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.hotelName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantAddress}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.region}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.siteStatus}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.siteVerificationDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.expectedCompletionDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.remainingDays}</TableCell>
                                : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const CaravanReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvCaravanReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Address</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Applicant Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Region</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Status</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Site Verification Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Expected Completion Date</TableCell>
                            : null
                        }
                        {reportType === "sitevisitreminderapplicationdetails" ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Remaining Days</TableCell>
                            : null
                        }
                    </TableRow>
                    {caravanReportData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantAddress}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.applicantType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.region}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.siteStatus}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.siteVerificationDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{moment(row.expectedCompletionDate).format('DD/MM/YYYY')}</TableCell>
                                : null
                            }
                            {reportType === "sitevisitreminderapplicationdetails" ?
                                <TableCell style={{ fontSize: '14px' }}>{row.remainingDays}</TableCell>
                                : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Applicant Details (New)
                                                <Radio
                                                    checked={reportType === 'applicationdetails'}
                                                    onChange={handleChange}
                                                    value="applicationdetails"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Applicant Details (New)"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                                <br />
                                                <br />
                                                <br />
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Approved Applicant Details
                                                <Radio
                                                    checked={reportType === 'approvedapplicationdetails'}
                                                    onChange={handleChange}
                                                    value="approvedapplicationdetails"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Approved Applicant Details"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                                <div style={{ color: 'green', fontWeight: 'bold' }}>DTO Approval For Agro, Land, Air Water (Other than Konkan)/ <br /> Director Approval For Water (Konkan), Accomodation And Caravan</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Site Visit Approved Applicant Details
                                                <Radio
                                                    checked={reportType === 'siteapprovedapplicationdetails'}
                                                    onChange={handleChange}
                                                    value="siteapprovedapplicationdetails"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Site Visit Approved Applicant Details"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                                <br />
                                                <br />
                                                <br />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                Site Visit Reminder Applicant Details
                                                <Radio
                                                    checked={reportType === 'sitevisitreminderapplicationdetails'}
                                                    onChange={handleChange}
                                                    value="sitevisitreminderapplicationdetails"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Site Visit Reminder Applicant Details"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Applicant Details (Renewed)
                                                <Radio
                                                    checked={reportType === 'renewedapplicationdetails'}
                                                    onChange={handleChange}
                                                    value="renewedapplicationdetails"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Applicant Details (Renewed)"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                                <br />
                                                <br />
                                                <br />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                From Date
                                                <DatePicker
                                                    id="fromDate"
                                                    selected={fromDate}
                                                    onChange={date => setFromDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    disabled={reportType === "sitevisitreminderapplicationdetails"}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                To Date
                                                <DatePicker
                                                    id="toDate"
                                                    selected={toDate}
                                                    onChange={date => setToDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    disabled={reportType === "sitevisitreminderapplicationdetails"}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                <Select
                                                    options={regionData}
                                                    name="region"
                                                    value={regionId}
                                                    onChange={regionChangeHandler}
                                                    isSearchable
                                                    isDisabled={dtoUser}
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px' }}>
                                                <Select className="basic-single"
                                                    options={districtData}
                                                    name="district"
                                                    value={districtId}
                                                    onChange={districtChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px' }}>
                                                <Select className="basic-single"
                                                    options={tourTypeData}
                                                    name="tourtype"
                                                    value={tourType}
                                                    onChange={tourTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourtype"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <MDBox mt={4} mb={1} textAlign="center">
                                                    {loading ?
                                                        <Circles
                                                            heigth="20"
                                                            width="20"
                                                            color='grey'
                                                            ariaLabel='loading'
                                                        />

                                                        :
                                                        <MDButton color="info" onClick={submitHandler}>
                                                            Show
                                                        </MDButton>
                                                    }
                                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showAgroReport ? <AgroReport /> : null}
                                                {showAdventureReport ? <AdventureReport /> : null}
                                                {showAccomodationReport ? <AccomodationReport /> : null}
                                                {showCaravanReport ? <CaravanReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {showAgroReport ?
                                                <TableCell align="center">
                                                    <ReactHTMLTableToExcel table="dvAgroReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                        <TableRow>
                                            {showAdventureReport ?
                                                <TableCell align="center">
                                                    <ReactHTMLTableToExcel table="dvAdventureReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                        <TableRow>
                                            {showCaravanReport ?
                                                <TableCell align="center">
                                                    <ReactHTMLTableToExcel table="dvCaravanReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                        <TableRow>
                                            {showAccomodationReport ?
                                                <TableCell align="center">
                                                    <ReactHTMLTableToExcel table="dvAccomodationReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
