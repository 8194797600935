
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function ChangePassword() {
    const [state, setState] = useState({ oldpassword: '', newpassword: '', confirmpassword: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();
    
    useEffect(() => {
       
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.oldpassword) {
            isValid = false;
            errs[".oldpassword"] = "Please enter old password";
        }
        else if(state.oldpassword && state.oldpassword !== JSON.parse(window.sessionStorage.getItem('userData')).password){
            isValid = false;
            errs[".oldpassword"] = "Old Password is incorrect";
        }
        if (!state.newpassword) {
            isValid = false;
            errs[".newpassword"] = "Please enter new password";
        }
        if (!state.confirmpassword) {
            isValid = false;
            errs[".confirmpassword"] = "Please enter confirm password";
        }
        else if(state.newpassword && state.confirmpassword && state.newpassword !== state.confirmpassword){
            isValid = false;
            errs[".confirmpassword"] = "New Password and Confirm Password are not matching";
        }        
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.CHANGE_PASSWORD,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        UserId: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                        Password: state.newpassword,                                               
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/authentication/sign-in/basic');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);                        
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Change Password
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2}>
                                            <MDInput label="Old Password" fullWidth value={state.oldpassword || ''}
                                                name="oldpassword"
                                                onChange={changeHandler}
                                                id="oldpassword"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".oldpassword"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="New Password" fullWidth value={state.newpassword || ''}
                                                name="newpassword"
                                                onChange={changeHandler}
                                                id="newpassword"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".newpassword"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Confirm Password" fullWidth value={state.confirmpassword || ''}
                                                name="confirmpassword"
                                                onChange={changeHandler}
                                                id="confirmpassword"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".confirmpassword"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ChangePassword;