import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import Select from 'react-select';
import * as moment from 'moment';
import * as service from '../../services/service'

function AdventureTourRegistrations() {
    const [search, setSearch] = useState('');
    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [status, setStatus] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activityCategory, setActivityCategory] = useState('');
    const statusData = [{ value: '', label: 'All' }, { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' }, { value: 'Rejected', label: 'Rejected' }];

    const statusDataForWater = [{ value: '', label: 'All' }, { value: 'Pending at DoT', label: 'Pending at DoT' },
    { value: 'Pending at Assistant Director', label: 'Pending at Assistant Director' },
    { value: 'Pending at Joint Director', label: 'Pending at Joint Director' },
    { value: 'Pending at Director', label: 'Pending at Director' },
    { value: 'Approved by DoT', label: 'Approved by DoT' },
    { value: 'Approved by Assistant Director', label: 'Approved by Assistant Director' },
    { value: 'Approved by Joint Director', label: 'Approved by Joint Director' },
    { value: 'Approved by Director', label: 'Approved by Director' },
    { value: 'Rejected by DoT', label: 'Rejected by DoT' },
    { value: 'Rejected by Assistant Director', label: 'Rejected by Assistant Director' },
    { value: 'Rejected by Joint Director', label: 'Rejected by Joint Director' },
    { value: 'Rejected by Director', label: 'Rejected by Director' }];

    const activityCategoryData = [{ label: "Land Activities", value: "Land Activities" },
    { label: "Water Activities", value: "Water Activities" }, { label: "Air Activities", value: "Air Activities" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;
    const regId = JSON.parse(sessionStorage.getItem('userData')).regionId;
    const reg = JSON.parse(sessionStorage.getItem('userData')).region;
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();
    const getRegions = () => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: 1, // for Maharashtra
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setRegionData(data2);
                if (userRl !== "DTO") {
                    setRegionId(all);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);
            });
    };
    const submitHandler = () => {
        let stus = '';
        let asststus = '';
        let jtstus = '';
        let dirstus = '';
        if (status.value === 'Pending at DoT') {
            stus = 'Pending'
        }
        else if (status.value === 'Approved by DoT') {
            stus = 'Approved'
        }
        else if (status.value === 'Rejected by DoT') {
            stus = 'Rejected'
        }
        if (status.value === 'Pending') {
            stus = 'Pending'
        }
        else if (status.value === 'Approved') {
            stus = 'Approved'
        }
        else if (status.value === 'Rejected') {
            stus = 'Rejected'
        }
        else if (status.value === 'Pending at Assistant Director') {
            asststus = 'Pending'
        }
        else if (status.value === 'Approved by Assistant Director') {
            asststus = 'Approved'
        }
        else if (status.value === 'Rejected by Assistant Director') {
            asststus = 'Rejected'
        }
        else if (status.value === 'Pending at Joint Director') {
            jtstus = 'Pending'
        }
        else if (status.value === 'Approved by Joint Director') {
            jtstus = 'Approved'
        }
        else if (status.value === 'Rejected by Joint Director') {
            jtstus = 'Rejected'
        }
        else if (status.value === 'Pending at Director') {
            dirstus = 'Pending'
        }
        else if (status.value === 'Approved by Director') {
            dirstus = 'Approved'
        }
        else if (status.value === 'Rejected by Director') {
            dirstus = 'Rejected'
        }       
        fetch(
            service.GET_ADVENTURE_TOUR_REGISTRATIONS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    Search: search,
                    Status: stus,
                    AssistantDirectorStatus: asststus,
                    JointDirectorStatus: jtstus,
                    DirectorStatus: dirstus,
                    RegionId: regionId ? regionId.value : 0,
                    DistrictId: districtId ? districtId.value : 0,
                })
            })
            .then(res => res.json())
            .then(result => {                
                if (result[0].message.messageType === 'Failed') {
                    setError('No Data Found');
                    setData([]);
                }
                else if (activityCategory.value !== '') {                    
                    const result2 = result.filter(ele => ele.activityCategory === activityCategory.value);
                    setData(result2);
                    setError('');
                }
                else {
                    setData(result);
                    setError('');
                }
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictData(null);
        getDistricts(ev.value);
        setData([]);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
        setData([]);
    }
    const statusChangeHandler = (ev) => {
        setStatus(ev);
        setData([]);
    }
    const activityCategoryChangeHandler = (ev) => {
        setActivityCategory(ev);
        setData([]);
    }
    const handleEdit = (id) => {
        // localStorage.setItem('search', JSON.stringify(search));
        // localStorage.setItem('ActivityCategory', JSON.stringify(activityCategory.value));
        // localStorage.setItem('Status', status ? JSON.stringify(status.value) : null);
        // localStorage.setItem('RegionId', regionId ? JSON.stringify(regionId.value) : 0);
        // localStorage.setItem('Region', regionId ? JSON.stringify(regionId.label) : 'All');
        // localStorage.setItem('DistrictId', districtId ? JSON.stringify(districtId.value) : 0);
        // localStorage.setItem('District', districtId ? JSON.stringify(districtId.label) : 'All');
        navigate(`/viewadventuretourregistrationdetails/${id}`);
    }
    const handleSiteVisit = (id) => {
        navigate(`/adventuretoursitevisit/${id}`);
    }
    const handleCertificate = (id) => {
        navigate(`/adventurecertificate/${id}`);
    }
    useEffect(() => {
        setLoading(true);
        getRegions();
        const cat = { label: 'Land Activities', value: 'Land Activities' };
        setActivityCategory(cat);

        if (userRl === "DTO") {
            const r = { label: reg, value: regId };
            setRegionId(r);
            getDistricts(regId);
        }
        
        // if (JSON.parse(localStorage.getItem('ActivityCategory')) != null) {
        //     const item = ({ value: JSON.parse(localStorage.getItem('ActivityCategory')), label: JSON.parse(localStorage.getItem('ActivityCategory')) });
        //     setActivityCategory(item);

        //     if (JSON.parse(localStorage.getItem('search')) !== null) {
        //         const srch = JSON.parse(localStorage.getItem('search'));
        //         setSearch(srch);
        //     }
        //     if (JSON.parse(localStorage.getItem('Status')) !== null) {
        //         const st = ({ value: JSON.parse(localStorage.getItem('Status')), label: JSON.parse(localStorage.getItem('Status')) });
        //         setStatus(st);
        //     }

        //     if (JSON.parse(localStorage.getItem('RegionId')) !== null) {
        //         const rg = ({ value: JSON.parse(localStorage.getItem('RegionId')), label: JSON.parse(localStorage.getItem('Region')) });
        //         setRegionId(rg);
        //         getDistricts(rg.value);
        //     }

        //     if (JSON.parse(localStorage.getItem('DistrictId')) !== null) {
        //         const dt = ({ value: JSON.parse(localStorage.getItem('DistrictId')), label: JSON.parse(localStorage.getItem('District')) });
        //         setDistrictId(dt);
        //     }

        //     submitHandler();
        //     localStorage.setItem('search', null);
        //     localStorage.setItem('ActivityCategory', null);
        //     localStorage.setItem('Status', null);
        //     localStorage.setItem('RegionId', null);
        //     localStorage.setItem('Region', null);
        //     localStorage.setItem('DistrictId', null);
        //     localStorage.setItem('District', null);
        // };
        setLoading(false);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Adventure Tour Registrations
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <Table>
                                    <TableRow>
                                        <TableCell width="15%">
                                            <br />
                                            <MDInput label="search"
                                                value={search}
                                                name="search"
                                                onChange={ev => setSearch(ev.target.value)}
                                                id="search"
                                                required
                                            />
                                        </TableCell>
                                        <TableCell width="20%">
                                            Activity Category
                                            <div style={{ fontSize: '14px' }}>
                                                <Dropdown menuClassName='myMenuClassName' id="activityCategory"
                                                    name="activityCategory"
                                                    options={activityCategoryData}
                                                    value={activityCategory}
                                                    onChange={activityCategoryChangeHandler}
                                                    label="activityCategory"
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell width="15%">
                                            Status
                                            <div style={{ fontSize: '14px' }}>
                                                <Dropdown menuClassName='myMenuClassName' id="status"
                                                    name="status"
                                                    options={activityCategory.value !== 'Water Activities' ? statusData : statusDataForWater}
                                                    value={status}
                                                    onChange={statusChangeHandler}
                                                    label="Status"
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell width="15%">
                                            Region
                                            <Select
                                                options={regionData}
                                                name="region"
                                                value={regionId}
                                                onChange={regionChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                                placeholder="Select Region"
                                                isDisabled={userRl === "DTO"}
                                            />
                                        </TableCell>
                                        <TableCell width="20%">
                                            District
                                            <Select
                                                options={districtData}
                                                name="district"
                                                value={districtId}
                                                onChange={districtChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                                placeholder="Select District"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <br />
                                            <MDButton color="info" endIcon={<SearchIcon />} onClick={submitHandler}>
                                                Search
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                    <div style={{ fontSize: '12px', color: 'green', paddingLeft: '20px' }}>Search by Mobile No. or Email Id</div>
                                </Table>
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    <div align='center' style={{ fontSize: '12px', color: 'red' }}>{error}</div>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                            <Table aria-label="simple table">

                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                                                    <TableCell align='center' style={{ fontWeight: 'bold' }}>Actions</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Applicant Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Applicant Type</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Region</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>District</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Registration</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row, i) => (
                                                        <TableRow
                                                            key={row.adventureRegistrationId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{i + 1}</TableCell>
                                                            <TableCell>
                                                                <MDButton onClick={() => handleEdit(row.adventureRegistrationId)}>View Details</MDButton>
                                                                <MDButton onClick={() => handleSiteVisit(row.adventureRegistrationId)}>Site Details</MDButton>
                                                                <MDButton onClick={() => handleCertificate(row.adventureRegistrationId)}
                                                                    disabled={row.documentStatus === 'Rejected'
                                                                        || (row.activityCategory === 'Water Activities' && reg === 'Konkan' && row.directorStatus !== 'Approved')
                                                                    }>Provisional Certificate</MDButton>
                                                            </TableCell>
                                                            {/* <TableCell><MDButton onClick={() => handleEdit(row.adventureRegistrationId)}>View Details</MDButton></TableCell>
                                                            <TableCell><MDButton onClick={() => handleSiteVisit(row.adventureRegistrationId)}>Site Details</MDButton></TableCell>
                                                            <TableCell>
                                                                <MDButton onClick={() => handleCertificate(row.adventureRegistrationId)}
                                                                    disabled={row.documentStatus === 'Rejected'
                                                                        || (row.activityCategory === 'Water Activities' && row.directorStatus !== 'Approved')
                                                                    }>Provisional Certificate</MDButton>
                                                            </TableCell> */}
                                                            <TableCell>{row.applicantName}</TableCell>
                                                            <TableCell>{row.emailId}</TableCell>
                                                            <TableCell>{row.mobileNo}</TableCell>
                                                            <TableCell>{row.applicantAddress}</TableCell>
                                                            <TableCell>{row.applicantType}</TableCell>
                                                            <TableCell>{row.region}</TableCell>
                                                            <TableCell>{row.district}</TableCell>
                                                            <TableCell>{row.status}</TableCell>
                                                            <TableCell>{moment(row.addedDate).format('DD/MM/YYYY')}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </div>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AdventureTourRegistrations;