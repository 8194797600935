
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { Document, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import * as moment from 'moment';
import certLogo from "assets/images/CertificateLogo.png";
import satyaLogo from "assets/images/CertificateLogoOld.png";
import shasan from "assets/images/MaharashtraShasan.png";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function GenerateCaravanCertificate() {
    const [data, setData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const certificateId = JSON.parse(sessionStorage.getItem('CertificateId'));
    const certificateNumber = JSON.parse(sessionStorage.getItem('CertificateNumber'));
    const outwardNumber = JSON.parse(sessionStorage.getItem('OutwardNumber'));
    const validFrom = JSON.parse(sessionStorage.getItem('ValidFrom'));
    const validTill = JSON.parse(sessionStorage.getItem('ValidTill'));
    const issueDate = JSON.parse(sessionStorage.getItem('IssueDate'));
    const navigate = useNavigate();
    const params = useParams();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_CARAVAN_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        CaravanRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getRegistrationById();
        }
    }, []);


    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        const divToPrint = document.querySelector('#divForm');
        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            // eslint-disable-next-line new-cap
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            // const doc1 = addWaterMark(doc);
            const blob = doc.output('blob');
            // doc.toBlob(blob => {
            const certFile = new File([blob], 'Certificate.pdf');
            const formData = new FormData();
            formData.append("CaravanRegistrationId", params.id ? params.id : null);
            formData.append("CaravanCertificateId", certificateId);
            formData.append("IssueDate", (new Date(issueDate)).toUTCString());
            formData.append("ValidFrom", (new Date(validFrom)).toUTCString());
            formData.append("ValidTill", (new Date(validTill)).toUTCString());
            formData.append("CertificateNumber", certificateNumber);
            formData.append("OutwardNumber", outwardNumber);
            formData.append("CertificateFile", certFile);
            formData.append("AddedBy", JSON.parse(window.sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_CARAVAN_CERTIFICATE,
                {
                    method: 'POST',
                    headers: {                  
                        'XApiKey': apiKey
                    },
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/caravanregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/caravanregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        });
        // }, 'image/png')
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/caravanregistrations');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Certificate
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                {/* <Document style={{ width: '100%' }}> */}
                                <div id="divForm" width="100%">
                                    <br />
                                    <br />
                                    <Table id="tblCertificate" style={{ "borderWidth": "5px", 'borderColor': "lightcoral", 'borderStyle': 'solid' }}>
                                        <TableRow>
                                            <TableCell align="left" width="30%"> 
                                                <br />
                                                <img src={shasan} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                            <TableCell align="center" width="30%">
                                                <br />
                                                <img src={satyaLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                            <TableCell align="right">
                                                <br />
                                                <img src={certLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" align="center" style={{ fontSize: '22px', fontWeight: 'bold', color: 'maroon' }}>
                                                पर्यटन संचालनालय
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" align="center" style={{ fontSize: '18px', fontWeight: 'bold', color: 'maroon' }}>
                                                शासन निर्णय क्र.  टिडीएस  2021/02/ प्र. क्र . 501/ पर्यटन ,दिनांक 15.03.2021
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                कॅरव्हॅन  पर्यटन धोरण
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                कॅरव्हॅन नोंदणी प्रामाणप्रत्र
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                Caravan Registration Certificate
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी क्रमांक (Registration No.)
                                            </TableCell>
                                            <TableCell  colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {certificateNumber}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                विभागीय कार्यालय (Divisional Office)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.region}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी धारकाचे नाव श्री /श्रीमती /संस्था /कंपनी (Applicant`s Name Shri/Smt/Organization)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.applicantName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कार्यालयाचा पत्ता  (Applicant`s Office Address)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.address}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कॅरव्हॅनचा प्रकार (Type Of Caravan)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.singleAxle ? "Single Axle Conventional Caravan" : ''}
                                                {data.twinAxle ? "Twin Axle Caravan" : ''}
                                                {data.tentTrailer ? "Tent Trailer" : ''}
                                                {data.foldingCaravan ? "Folding Caravan" : ''}
                                                {data.camperTrailer ? "CamperTrailer" : ''}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                पर्यटक वाहन क्षमता (Tourist Carrying Capacity)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.peopleNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                बेड्सची संख्या  (Number of Beds)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.bedNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कॅरव्हॅनचा नोंदणी क्रमांक (Vehicle Registration Number)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.vehicleRegistrationNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी प्रमाणपत्राचा कालावधी (Registration valid for the period of)
                                            </TableCell>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {moment(validFrom).format('DD-MM-YYYY')} To {moment(validTill).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="3" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                Issue Date: {moment(issueDate).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell id="tcSignature" colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={signatureImage} alt="sign" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="3" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <br />
                                                <br />
                                                {JSON.parse(sessionStorage.getItem('userData')).userName}
                                                <br />
                                                <br />
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={stampImage} alt="stamp" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="3" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                * शासन निर्णय क्र. टिडीएस 2021/02/प्र.क्र.501/पर्यटन, दिनांक 15.03.2021 अन्वये जाहीर केलेल्या कॅरॅव्हॅन पर्यटन धोरणातील तरतूदींचे काटेकोर पालन करण्याच्या अधिन राहुन प्रमाणपत्र देण्यात येत आहे.
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </div>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    Save
                                                </MDButton>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <MDButton color="secondary" onClick={backHandler}>
                                                Back
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".error"]}</div>
                                {/* </Document> */}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default GenerateCaravanCertificate;