
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import * as moment from 'moment';
import * as service from '../../services/service';

function CaravanCertificate() {
    const [state, setState] = useState({ filename: '', filepath: '', certificatenumber: '', outwardnumber: '', certificateid: '0' });    
    const [issueDate, setIssueDate] = useState(new Date());
    const [validFromDate, setValidFromDate] = useState(new Date());
    const [validTillDate, setValidTillDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);    
    const [region, setRegion] = useState('');     
    const navigate = useNavigate();
    const params = useParams();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_CARAVAN_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        CaravanRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // setActivityCategory(result.activityCategory);
                        setRegion(result.region);                        
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getCertificateNumber = (date) => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_CARAVAN_CERTIFICATE_NUMBER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        Region: region,
                        ValidFrom: new Date(date).toUTCString(),
                        Type: 'Caravan'
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        setState({
                            ...state, certificatenumber: result.message
                        });
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_CARAVAN_CERTIFICATE_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        CaravanRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, filename: result.fileName, filepath: result.filePath,
                            certificatenumber: result.certificateNumber, outwardnumber: result.outwardNumber,
                            certificateid: result.caravanCertificateId
                        });
                        setIssueDate(new Date(result.issueDate));
                        setValidFromDate(new Date(result.validFrom));
                        setValidTillDate(new Date(result.validTill));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getRegistrationById();
            getDetailsById();
        }
    }, []);    

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const ValidFromDateHandler = (date) => {
        setValidFromDate(date);
        getCertificateNumber(date);
    };    

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                headers: {                  
                    'XApiKey': apiKey
                },
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const backHandler = () => {
        navigate('/caravanregistrations');
    };
    const generateCertificateHandler = () => {
        if (!state.certificatenumber) {
            // eslint-disable-next-line
            alert("Invalid Certificate Number");
        }
        else {
            sessionStorage.setItem('CertificateNumber', JSON.stringify(state.certificatenumber));
            sessionStorage.setItem('ValidFrom', JSON.stringify(validFromDate));
            sessionStorage.setItem('ValidTill', JSON.stringify(validTillDate));
            sessionStorage.setItem('IssueDate', JSON.stringify(issueDate));
            sessionStorage.setItem('OutwardNumber', JSON.stringify(state.outwardnumber));
            sessionStorage.setItem('CertificateId', JSON.stringify(state.certificateid));
            navigate(`/generatecaravancertificate/${params.id}`);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <div style={{ fontWeight: 'bold' }}> Valid From Date </div>
                                            <DatePicker
                                                id="validFromDate"
                                                selected={validFromDate}
                                                onChange={date => ValidFromDateHandler(date)}
                                                dateFormat="MM/dd/yyyy"
                                                tabIndex={0}
                                                disabled={state.certificateid !== '0'}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".validFromDate"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <div style={{ fontWeight: 'bold' }}> Valid Till Date </div>
                                            <DatePicker
                                                id="validTillDate"
                                                selected={validTillDate}
                                                onChange={date => setValidTillDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                                tabIndex={0}
                                                disabled={state.certificateid !== '0'}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".validTillDate"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <div style={{ fontWeight: 'bold' }}>Issue Date</div>
                                            <DatePicker
                                                id="issueDate"
                                                selected={issueDate}
                                                onChange={date => setIssueDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                                tabIndex={0}
                                                disabled={state.certificateid !== '0'}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".issueDate"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <div style={{ fontWeight: 'bold' }}>Outward Number</div>
                                            <MDInput fullWidth value={state.outwardnumber || ''}
                                                name="outwardnumber"
                                                onChange={changeHandler}
                                                id="outwardnumber"
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".outwardnumber"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <div style={{ fontWeight: 'bold' }}>Certificate Number</div>
                                            <MDInput fullWidth value={state.certificatenumber || ''}
                                                name="certificatenumber"
                                                onChange={changeHandler}
                                                id="certificatenumber"
                                                disabled
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".certificatenumber"]}</div>
                                        </MDBox>
                                        {state.filename ?
                                            <MDButton onClick={() => handleDownload(state.filename, state.filepath)}>{state.filename}</MDButton>
                                            : null}
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={generateCertificateHandler}>
                                                            Generate Certificate
                                                        </MDButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CaravanCertificate;