
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import Select from 'react-select';
import { TextField } from "@mui/material";
import * as service from '../../../../services/service';

function AddEditUser() {
    const [state, setState] = useState({
        username: '', emailid: '', mobileno: '', password: '', isactive: '',
        signaturefilename: '', signaturefilepath: '', stampfilename: '', stampfilepath: ''
    });
    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [signatureImage, setSignatureImage] = useState('');
    const [stampImage, setStampImage] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [signatureFile, setSignatureFile] = useState('');
    const [stampFile, setStampFile] = useState('');
    const userRoleData = [{ label: 'DTO', value: 'DTO' }, { label: 'DoT', value: 'DoT' }, { label: 'Assistant Director', value: 'Assistant Director' }
        , { label: 'Joint Director', value: 'Joint Director' }, { label: 'Director', value: 'Director' }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const params = useParams();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getRegions = () => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: 1, // for Maharashtra
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_USER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        UserId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, username: result.userName, emailid: result.emailId, mobileno: result.mobileNo,
                            password: result.password, isactive: result.isActive,
                            signaturefilename: result.signatureFileName, signaturefilepath: result.signatureFilePath,
                            stampfilename: result.stampFileName, stampfilepath: result.stampFilePath,
                        });

                        setSignatureImage(`data:image/png;base64,${result.signatureFilePath}`);
                        setStampImage(`data:image/png;base64,${result.stampFilePath}`);

                        const rl = { label: result.userRole, value: result.userRole };
                        setUserRole(rl);
                        if (result.userRole === "DTO") {
                            getRegions();
                            const rg = { label: result.region, value: result.regionId };
                            setRegionId(rg);
                        }
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
    }
    const userRoleChangeHandler = (ev) => {
        setUserRole(ev);
        if (ev.value === "DTO") {
            getRegions();
        }
        else {
            setRegionData([]);
        }
    }
    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        const maxSize = 1024; // in KB = 1MB
        if (!state.username) {
            isValid = false;
            errs[".username"] = "Please enter user name";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobile no.";
        }
        if (!userRole) {
            isValid = false;
            errs[".userRole"] = "Please select user role";
        }
        if (userRole.value === "DTO" && !regionId) {
            isValid = false;
            errs[".regionId"] = "Please select region";
        }
        if (params.id === 0 && !signatureFile) {
            isValid = false;
            errs[".signatureFile"] = "Please upload file";
        }
        if (params.id === 0 && !stampFile) {
            isValid = false;
            errs[".stampFile"] = "Please upload file";
        }
        if (signatureFile && getExtension(signatureFile.name).toLowerCase() !== 'jpg'
            && getExtension(signatureFile.name).toLowerCase() !== 'jpeg') {
            isValid = false;
            errs[".signatureFile"] = "Please upload jpg/jpeg file";
        }
        if (stampFile && getExtension(stampFile.name).toLowerCase() !== 'jpg'
            && getExtension(stampFile.name).toLowerCase() !== 'jpeg') {
            isValid = false;
            errs[".stampFile"] = "Please upload jpg/jpeg file";
        }
        if (signatureFile && signatureFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".signatureFile"] = "File size limit 1 MB";
        }
        if (stampFile && stampFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".stampFile"] = "File size limit 1 MB";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    // const handleDownload = (fileName, filePath) => {
    //     setLoading(true);
    //     const formData = new FormData();
    //     formData.append("FileName", fileName);
    //     formData.append("FilePath", filePath);
    //     fetch(
    //         service.DOWNLOAD_FILE,
    //         {
    //             method: 'POST',
    //             body: formData
    //         })
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             // Create blob link to download
    //             const url = window.URL.createObjectURL(
    //                 new Blob([blob]),
    //             );
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute(
    //                 'download',
    //                 fileName,
    //             );
    //             // Append to html link element page
    //             document.body.appendChild(link);
    //             // Start download
    //             link.click();
    //             // Clean up and remove the link
    //             link.parentNode.removeChild(link);
    //         });

    //     setLoading(false);
    // };
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("UserId", params.id ? params.id : null);
            formData.append("UserName", state.username);
            formData.append("MobileNo", state.mobileno);
            formData.append("EmailId", state.emailid);
            formData.append("Password", state.password ? state.password : state.mobileno);
            formData.append("RegionId", regionId ? regionId.value : 0);
            formData.append("UserRole", userRole.value);
            formData.append("AddedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            formData.append("UpdatedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            formData.append("SignatureFile", signatureFile);
            formData.append("StampFile", stampFile);
            fetch(
                service.ADD_UPDATE_USER,
                {
                    method: 'POST',
                    headers: {                  
                        'XApiKey': apiKey
                    },
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/user');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/user');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                {/* <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}> */}
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="User Name" fullWidth value={state.username || ''}
                                                    name="username"
                                                    onChange={changeHandler}
                                                    id="username"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".username"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                    name="mobileno"
                                                    onChange={changeHandler}
                                                    id="mobileno"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                    name="emailid"
                                                    type="email"
                                                    onChange={changeHandler}
                                                    id="emailid"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    options={userRoleData}
                                                    name="userRole"
                                                    value={userRole}
                                                    onChange={userRoleChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    placeholder="Select User Role"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".userRole"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    options={regionData}
                                                    name="region"
                                                    value={regionId}
                                                    onChange={regionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    placeholder="Select Region"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".regionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                <span style={{ color: 'red' }}>*</span>Signature (File Size 1 MB)
                                                <br />
                                                <TextField
                                                    id="signatureFile"
                                                    type="file"
                                                    onChange={ev => setSignatureFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".signatureFile"]}</div>
                                                {state.signaturefilename ?
                                                    // <MDButton onClick={() => handleDownload(state.signaturefilename, state.signaturefilepath)}>{state.signaturefilename}</MDButton>
                                                    <img src={signatureImage} alt="sign" height="100px" width="100px" />
                                                    : null}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                <span style={{ color: 'red' }}>*</span>Stamp (File Size 1 MB)
                                                <br />
                                                <TextField
                                                    id="stampFile"
                                                    type="file"
                                                    onChange={ev => setStampFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stampFile"]}</div>
                                                {state.stampfilename ?
                                                    // <MDButton onClick={() => handleDownload(state.stampfilename, state.stampfilepath)}>{state.stampfilename}</MDButton>
                                                    <img src={stampImage} alt="stamp" height="100px" width="100px" />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditUser;